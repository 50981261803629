const de = {
  common: {
    or: 'or',
    cancel: 'Cancel',
    reset: 'Reset',
    save: 'Save',
    addTeamMember: 'Add Team Member',
    addProjectManager: 'Add Project Manager',
    saveDraft: 'Save Draft',
    search: 'Search',
    edit: 'Edit',
    remove: 'Remove',
    new: 'New',
    export: 'Export to Excel',
    noDataToExport: 'No data to export',
    import: 'Import',
    discard: 'Discard',
    yes: 'Yes',
    no: 'No',
    pause: 'Pause',
    areYouSure: 'Are you sure?',
    view: 'View',
    destroy: 'Delete',
    mustSelectARow: 'Must select a row',
    confirm: 'Confirm',
    requestRelease: 'Request Release',
    revise: 'Revise',
    release: 'Release',
    actions: 'Actions',
    requestContractsReview: 'Request Contracts Review',
    finish: 'Finish',
    newProjectBtn: 'New project',
    editProjectBtn: 'Edit project',
    changePassword: 'Change password',
    updatePassword: 'Update password',
    password: 'Kenwort',
  },

  app: {
    title: 'Project Management',
  },

  entities: {
    passwordChange: {
      title: 'Change password',
      currentPassword: 'Current password',
      newPassword: 'New password',
      confirmNewPassword: 'Confirm new password',
      containsEightCharacters: '8 Characters',
      containsNumber: 'Contains Number',
      containsUppercase: 'Contains Uppercase',
      containsSpecialCharacter: 'Contains Special Character',
      passwordUpdateSuccess: 'Password successfully updated',
      passwordUpdateFailed: 'Password update failed',
    },

    project: {
      name: 'project',
      label: 'Projects',
      menu: 'Projects',
      exporterFileName: 'project_export',
      list: {
        menu: 'Projects',
        title: 'Projects',
      },
      create: {
        success: 'Project saved successfully',
      },
      update: {
        success: 'Project updated successfully',
      },
      destroy: {
        success: 'Project deleted successfully',
      },
      destroyAll: {
        success: 'Project(s) deleted successfully',
      },
      edit: {
        title: 'Edit Project',
      },
      fields: {
        id: 'Id',
        projectID: 'Project ID',
        projectCreate: 'Create Project',
        projectRelease: 'Project Release',
        projectImplementation: 'Project Implementation',
        projectFunding: 'Prepare Funding Application',
        projectReport: 'ProjectReport',
        status: 'Status',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Project',
      },
      view: {
        title: 'View Project',
      },
      importer: {
        title: 'Import Projects',
        fileName: 'project_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    projectCreate: {
      name: 'projectCreate',
      label: 'Project Create',
      menu: 'Project Create',
      exporterFileName: 'projectCreate_export',
      list: {
        menu: 'Project Create',
        title: 'Project Create',
      },
      create: {
        success: 'Create Project saved successfully',
      },
      update: {
        success: 'Create Project updated successfully',
      },
      destroy: {
        success: 'Create Project deleted successfully',
      },
      destroyAll: {
        success: 'Create Project(s) deleted successfully',
      },
      edit: {
        title: 'Edit Create Project',
      },
      fields: {
        id: 'Id',
        projectTitle: 'Project Title',
        projectDescription: 'Project Description',
        researchFocus: 'Research Focus',
        proposalResearchFocus: 'Proposal of Research Focus',
        subjectArea: 'Subject Area',
        proposalSubjectArea: 'Proposal of Subject Area',
        faculty: 'Faculty',
        projectTeam: 'ProjectTeam',
        cooperationPartnerEnum: 'Cooperation Partner',
        cooperationPartner: 'Cooperation Partner',
        costsPlan: 'Costs Plan',
        fundingAgencyEnum: 'Funding Agency Enum',
        fundingAgency: 'Funding Agency',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        cooperationPartnerEnum: {
          yes: 'Yes',
          no: 'No',
        },
        fundingAgencyEnum: {
          yes: 'Yes',
          no: 'No',
        },
      },
      new: {
        title: 'New Create Project',
      },
      view: {
        title: 'View Create Project',
      },
      importer: {
        title: 'Import Project Create',
        fileName: 'projectCreate_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    projectRelease: {
      name: 'projectRelease',
      label: 'Project Releases',
      menu: 'Project Releases',
      exporterFileName: 'projectRelease_export',
      list: {
        menu: 'Project Releases',
        title: 'Project Releases',
      },
      create: {
        success: 'Project Release saved successfully',
      },
      update: {
        success: 'Project Release updated successfully',
      },
      destroy: {
        success: 'Project Release deleted successfully',
      },
      destroyAll: {
        success: 'Project Release(s) deleted successfully',
      },
      edit: {
        title: 'Edit Project Release',
      },
      fields: {
        id: 'Id',
        faculty: 'Faculty',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Project Release',
      },
      view: {
        title: 'View Project Release',
      },
      importer: {
        title: 'Import Project Releases',
        fileName: 'projectRelease_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    projectReleaseRequest: {
      name: 'projectReleaseRequest',
      label: 'Project Release Request',
      menu: 'Cooperation Partners',

      create: {
        success: 'Cooperation Partner saved successfully',
      },
      update: {
        success: 'Cooperation Partner updated successfully',
      },
      destroy: {
        success: 'Cooperation Partner deleted successfully',
      },
      destroyAll: {
        success: 'Cooperation Partner(s) deleted successfully',
      },

      fields: {
        id: 'Id',
        decision: 'Decision',
        decisionDate: 'Decision date',
        comment: 'Comment',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        decision: {
          approved: 'Approved',
          rejected: 'Rejected',
        },
      },
    },

    projectImplementation: {
      name: 'projectImplementation',
      label: 'Project Implementations',
      menu: 'Project Implementations',
      exporterFileName: 'projectImplementation_export',
      list: {
        menu: 'Project Implementations',
        title: 'Project Implementations',
      },
      create: {
        success: 'Project Implementation saved successfully',
      },
      update: {
        success: 'Project Implementation updated successfully',
      },
      destroy: {
        success: 'Project Implementation deleted successfully',
      },
      destroyAll: {
        success: 'Project Implementation(s) deleted successfully',
      },
      edit: {
        title: 'Edit Project Implementation',
      },
      fields: {
        id: 'Id',
        ethicsCommitteeEnum: 'Ethics Committee',
        ethicsCommitteeDecision: 'Ethics Committee Decision',
        cooperationPartnerEnum: 'Cooperation Partner',
        cooperationPartnerContract: 'Cooperation Partner Contracts',
        employmentContractEnum: 'Employment Contract',
        employmentContract: 'Employment Contract',
        projectFunding: 'ProjectFunding',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        ethicsCommitteeEnum: {
          yes: 'Yes',
          no: 'No',
        },
        cooperationPartnerEnum: {
          yes: 'Yes',
          no: 'No',
        },
        employmentContractEnum: {
          yes: 'Yes',
          no: 'No',
        },
      },
      new: {
        title: 'New Project Implementation',
      },
      view: {
        title: 'View Project Implementation',
      },
      importer: {
        title: 'Import Project Implementations',
        fileName: 'projectImplementation_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    projectFunding: {
      name: 'projectFunding',
      label: 'Project Funding',
      menu: 'Project Funding',
      exporterFileName: 'projectFunding_export',
      list: {
        menu: 'Project Funding',
        title: 'Project Funding',
      },
      create: {
        success: 'Prepare Funding Application saved successfully',
      },
      update: {
        success: 'Prepare Funding Application updated successfully',
      },
      destroy: {
        success: 'Prepare Funding Application deleted successfully',
      },
      destroyAll: {
        success:
          'Prepare Funding Application(s) deleted successfully',
      },
      edit: {
        title: 'Edit Prepare Funding Application',
      },
      fields: {
        id: 'Id',
        funding: 'Funding',
        fundingAgency: 'Funding Agency',
        deadlineForSubmissionRange: 'Deadline for Submission',
        deadlineForSubmission: 'Deadline for Submission',
        availabilityOfGrantorDecisionRange:
          'Availability of Grantor Decision',
        availabilityOfGrantorDecision:
          'Availability of Grantor Decision',
        projectDurationFromRange: 'Project Duration From',
        projectDurationFrom: 'Project Duration From',
        projectDurationToRange: 'Project Duration To',
        projectDurationTo: 'Project Duration To',
        costPlan: 'Cost Plan',
        gantt: 'Gantt Chart',
        status: 'Status',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        funding: {
          thirdPartyFunding: 'Third-Party Funding',
          selfFinancedProject: 'SelfFinancedProject',
        },
      },
      new: {
        title: 'New Prepare Funding Application',
      },
      view: {
        title: 'View Prepare Funding Application',
      },
      importer: {
        title: 'Import Project Funding',
        fileName: 'projectFunding_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    projectReport: {
      name: 'projectReport',
      label: 'Project Reports',
      menu: 'Project Reports',
      exporterFileName: 'projectReport_export',
      list: {
        menu: 'Project Reports',
        title: 'Project Reports',
      },
      create: {
        success: 'Project Report saved successfully',
      },
      update: {
        success: 'Project Report updated successfully',
      },
      destroy: {
        success: 'Project Report deleted successfully',
      },
      destroyAll: {
        success: 'Project Report(s) deleted successfully',
      },
      edit: {
        title: 'Edit Project Report',
      },
      fields: {
        id: 'Id',
        projectReport: 'Project Report',
        internalReport: 'Internal Report',
        grantorsExtensionApproval: "Grantor's Extension Approval",
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Project Report',
      },
      view: {
        title: 'View Project Report',
      },
      importer: {
        title: 'Import Project Reports',
        fileName: 'projectReport_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    faculty: {
      name: 'faculty',
      label: 'Faculties',
      menu: 'Faculties',
      exporterFileName: 'faculty_export',
      list: {
        menu: 'Faculties',
        title: 'Faculties',
      },
      create: {
        success: 'Faculty saved successfully',
      },
      update: {
        success: 'Faculty updated successfully',
      },
      destroy: {
        success: 'Faculty deleted successfully',
      },
      destroyAll: {
        success: 'Faculty(s) deleted successfully',
      },
      edit: {
        title: 'Edit Faculty',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Faculty',
      },
      view: {
        title: 'View Faculty',
      },
      importer: {
        title: 'Import Faculties',
        fileName: 'faculty_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    location: {
      name: 'location',
      label: 'Locations',
      menu: 'Locations',
      exporterFileName: 'location_export',
      list: {
        menu: 'Locations',
        title: 'Locations',
      },
      create: {
        success: 'Location saved successfully',
      },
      update: {
        success: 'Location updated successfully',
      },
      destroy: {
        success: 'Location deleted successfully',
      },
      destroyAll: {
        success: 'Location(s) deleted successfully',
      },
      edit: {
        title: 'Edit Location',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        faculty: 'Faculty',
        status: 'Status',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Location',
      },
      view: {
        title: 'View Location',
      },
      importer: {
        title: 'Import Locations',
        fileName: 'location_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    centerInstitution: {
      name: 'centerInstitution',
      label: 'Centers / Institutions',
      menu: 'Centers / Institutions',
      exporterFileName: 'centerInstitution_export',
      doEnableSuccess: 'Center/Institution enabled successfully',
      doDisableSuccess: 'Center/Institution disabled successfully',
      doDisableAllSuccess:
        'Center/Institution(s) disabled successfully',
      doEnableAllSuccess:
        'Center/Institution(s) enabled successfully',
      list: {
        menu: 'Centers / Institutions',
        title: 'Centers / Institutions',
      },
      create: {
        success: 'Center / Institution saved successfully',
      },
      update: {
        success: 'Center / Institution updated successfully',
      },
      destroy: {
        success: 'Center / Institution deleted successfully',
      },
      destroyAll: {
        success: 'Center / Institution(s) deleted successfully',
      },
      edit: {
        title: 'Edit Center / Institution',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        faculty: 'Faculty',
        location: 'Location',
        status: 'Status',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Center / Institution',
      },
      view: {
        title: 'View Center / Institution',
      },
      importer: {
        title: 'Import Centers / Institutions',
        fileName: 'centerInstitution_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    outpatientClinic: {
      name: 'outpatientClinic',
      label: 'Outpatient Clinics',
      menu: 'Outpatient Clinics',
      exporterFileName: 'outpatientClinic_export',
      list: {
        menu: 'Outpatient Clinics',
        title: 'Outpatient Clinics',
      },
      create: {
        success: 'Outpatient Clinic saved successfully',
      },
      update: {
        success: 'Outpatient Clinic updated successfully',
      },
      destroy: {
        success: 'Outpatient Clinic deleted successfully',
      },
      destroyAll: {
        success: 'Outpatient Clinic(s) deleted successfully',
      },
      edit: {
        title: 'Edit Outpatient Clinic',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        faculty: 'Faculty',
        location: 'Location',
        headOfOutpatientClinic: 'Head of the Outpatient Clinic',
        address: 'Address',
        emailAddress: 'Email Address',
        disabled: 'Disabled',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Outpatient Clinic',
      },
      view: {
        title: 'View Outpatient Clinic',
      },
      importer: {
        title: 'Import Outpatient Clinics',
        fileName: 'outpatientClinic_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    subjectArea: {
      name: 'subjectArea',
      label: 'Subject Areas',
      menu: 'Subject Areas',
      exporterFileName: 'subjectArea_export',
      list: {
        menu: 'Subject Areas',
        title: 'Subject Areas',
      },
      create: {
        success: 'Subject Area saved successfully',
      },
      update: {
        success: 'Subject Area updated successfully',
      },
      destroy: {
        success: 'Subject Area deleted successfully',
      },
      destroyAll: {
        success: 'Subject Area(s) deleted successfully',
      },
      edit: {
        title: 'Edit Subject Area',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Subject Area',
      },
      view: {
        title: 'View Subject Area',
      },
      importer: {
        title: 'Import Subject Areas',
        fileName: 'subjectArea_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    researchFocus: {
      name: 'researchFocus',
      label: 'Research Focuses',
      menu: 'Research Focuses',
      exporterFileName: 'researchFocus_export',
      list: {
        menu: 'Research Focuses',
        title: 'Research Focuses',
      },
      create: {
        success: 'Research Focus saved successfully',
      },
      update: {
        success: 'Research Focus updated successfully',
      },
      destroy: {
        success: 'Research Focus deleted successfully',
      },
      destroyAll: {
        success: 'Research Focus(s) deleted successfully',
      },
      edit: {
        title: 'Edit Research Focus',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Research Focus',
      },
      view: {
        title: 'View Research Focus',
      },
      importer: {
        title: 'Import Research Focuses',
        fileName: 'researchFocus_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    fundingAgency: {
      name: 'fundingAgency',
      label: 'Funding Agencies',
      menu: 'Funding Agencies',
      exporterFileName: 'fundingAgency_export',
      list: {
        menu: 'Funding Agencies',
        title: 'Funding Agencies',
      },
      create: {
        success: 'Funding Agency saved successfully',
      },
      update: {
        success: 'Funding Agency updated successfully',
      },
      destroy: {
        success: 'Funding Agency deleted successfully',
      },
      destroyAll: {
        success: 'Funding Agency(s) deleted successfully',
      },
      edit: {
        title: 'Edit Funding Agency',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        management: 'Management',
        address: 'Address',
        contactPerson: 'Contact Person',
        contactEmail: 'Contact Email',
        phoneNumber: 'Phone Number',
        linkToWebsite: 'Link to Website',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Funding Agency',
      },
      view: {
        title: 'View Funding Agency',
      },
      importer: {
        title: 'Import Funding Agencies',
        fileName: 'fundingAgency_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    cooperationPartner: {
      name: 'cooperationPartner',
      label: 'Cooperation Partners',
      menu: 'Cooperation Partners',
      exporterFileName: 'cooperationPartner_export',
      list: {
        menu: 'Cooperation Partners',
        title: 'Cooperation Partners',
      },
      create: {
        success: 'Cooperation Partner saved successfully',
      },
      update: {
        success: 'Cooperation Partner updated successfully',
      },
      destroy: {
        success: 'Cooperation Partner deleted successfully',
      },
      destroyAll: {
        success: 'Cooperation Partner(s) deleted successfully',
      },
      edit: {
        title: 'Edit Cooperation Partner',
      },
      fields: {
        id: 'Id',
        lastName: 'Last Name',
        firstName: 'First Name',
        title: 'Title',
        affiliation: 'Affiliation',
        emailAddress: 'Email Address',
        phoneNumber: 'Phone Number',
        institutionName: 'Institution Name',
        address: 'Address',
        contactPerson: 'Contact Person',
        webAddress: 'WebAddress',
        partner: 'Partner',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        partner: {
          person: 'Person',
          institution: 'Institution',
        },
      },
      new: {
        title: 'New Cooperation Partner',
      },
      view: {
        title: 'View Cooperation Partner',
      },
      importer: {
        title: 'Import Cooperation Partners',
        fileName: 'cooperationPartner_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    viceDeanResearch: {
      name: 'viceDeanResearch',
      label: 'Deans Offices',
      menu: 'Deans Offices',
      exporterFileName: 'viceDeanResearch_export',
      list: {
        menu: 'Deans Offices',
        title: 'Deans Offices',
      },
      create: {
        success: 'Deans Office saved successfully',
      },
      update: {
        success: 'Deans Office updated successfully',
      },
      destroy: {
        success: 'Deans Office deleted successfully',
      },
      destroyAll: {
        success: 'Deans Office(s) deleted successfully',
      },
      edit: {
        title: 'Edit Deans Office',
      },
      fields: {
        id: 'Id',
        faculty: 'Faculty',
        phoneNumber: 'Phone Number',
        contactEmail: 'Contact Email',
        contactPerson: 'Contact Person',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Deans Office',
      },
      view: {
        title: 'View Deans Office',
      },
      importer: {
        title: 'Import Deans Offices',
        fileName: 'viceDeanResearch_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    chancellor: {
      name: 'chancellor',
      label: 'Chancellors',
      menu: 'Chancellors',
      exporterFileName: 'chancellor_export',
      list: {
        menu: 'Chancellors',
        title: 'Chancellors',
      },
      create: {
        success: 'Chancellor saved successfully',
      },
      update: {
        success: 'Chancellor updated successfully',
      },
      destroy: {
        success: 'Chancellor deleted successfully',
      },
      destroyAll: {
        success: 'Chancellor(s) deleted successfully',
      },
      edit: {
        title: 'Edit Chancellor',
      },
      fields: {
        id: 'Id',
        faculty: 'Faculty',
        contactEmail: 'Contact Email',
        contactPerson: 'Contact Person',
        phoneNumber: 'Phone Number',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Chancellor',
      },
      view: {
        title: 'View Chancellor',
      },
      importer: {
        title: 'Import Chancellors',
        fileName: 'chancellor_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    legalDepartment: {
      name: 'legalDepartment',
      label: 'Legal Departments',
      menu: 'Legal Departments',
      exporterFileName: 'legalDepartment_export',
      list: {
        menu: 'Legal Departments',
        title: 'Legal Departments',
      },
      create: {
        success: 'Legal Department saved successfully',
      },
      update: {
        success: 'Legal Department updated successfully',
      },
      destroy: {
        success: 'Legal Department deleted successfully',
      },
      destroyAll: {
        success: 'Legal Department(s) deleted successfully',
      },
      edit: {
        title: 'Edit Legal Department',
      },
      fields: {
        id: 'Id',
        contactEmail: 'Contact Email',
        contactPerson: 'Contact Person',
        phoneNumber: 'Phone Number',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Legal Department',
      },
      view: {
        title: 'View Legal Department',
      },
      importer: {
        title: 'Import Legal Departments',
        fileName: 'legalDepartment_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    humanResource: {
      name: 'humanResource',
      label: 'Human Resources',
      menu: 'Human Resources',
      exporterFileName: 'humanResource_export',
      list: {
        menu: 'Human Resources',
        title: 'Human Resources',
      },
      create: {
        success: 'Human Resource saved successfully',
      },
      update: {
        success: 'Human Resource updated successfully',
      },
      destroy: {
        success: 'Human Resource deleted successfully',
      },
      destroyAll: {
        success: 'Human Resource(s) deleted successfully',
      },
      edit: {
        title: 'Edit Human Resource',
      },
      fields: {
        id: 'Id',
        contactEmail: 'Contact Email',
        contactPerson: 'Contact Person',
        phoneNumber: 'Phone Number',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Human Resource',
      },
      view: {
        title: 'View Human Resource',
      },
      importer: {
        title: 'Import Human Resources',
        fileName: 'humanResource_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    teamMember: {
      name: 'teamMember',
      label: 'Team Members',
      menu: 'Team Members',
      exporterFileName: 'teamMember_export',
      list: {
        menu: 'Team Members',
        title: 'Team Members',
      },
      create: {
        success: 'Team Member saved successfully',
      },
      update: {
        success: 'Team Member updated successfully',
      },
      destroy: {
        success: 'Team Member deleted successfully',
      },
      destroyAll: {
        success: 'Team Member(s) deleted successfully',
      },
      edit: {
        title: 'Edit Team Member',
      },
      fields: {
        id: 'Id',
        user: 'User',
        contract: 'Contract',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Team Member',
      },
      view: {
        title: 'View Team Member',
      },
      importer: {
        title: 'Import Team Members',
        fileName: 'teamMember_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    testUser: {
      name: 'testUser',
      label: 'Test Users',
      menu: 'Test Users',
      exporterFileName: 'testUser_export',
      list: {
        menu: 'Test Users',
        title: 'Test Users',
      },
      create: {
        success: 'Test User saved successfully',
      },
      update: {
        success: 'Test User updated successfully',
      },
      destroy: {
        success: 'Test User deleted successfully',
      },
      destroyAll: {
        success: 'Test User(s) deleted successfully',
      },
      edit: {
        title: 'Edit Test User',
      },
      fields: {
        id: 'Id',
        faculty: 'Faculty',
        location: 'Location',
        centerInstitution: 'Center/Institution',
        academicTitle: 'Academic Title',
        statusMaContract: 'Status MA Contract',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        statusMaContract: {
          status1: 'Status1',
          statu2: 'Statu2',
          status3: 'Status3',
        },
      },
      new: {
        title: 'New Test User',
      },
      view: {
        title: 'View Test User',
      },
      importer: {
        title: 'Import Test Users',
        fileName: 'testUser_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    projectTeam: {
      name: 'projectTeam',
      label: 'Project Teams',
      menu: 'Project Teams',
      exporterFileName: 'projectTeam_export',
      list: {
        menu: 'Project Teams',
        title: 'Project Teams',
      },
      create: {
        success: 'Project Team saved successfully',
      },
      update: {
        success: 'Project Team updated successfully',
      },
      destroy: {
        success: 'Project Team deleted successfully',
      },
      destroyAll: {
        success: 'Project Team(s) deleted successfully',
      },
      edit: {
        title: 'Edit Project Team',
      },
      fields: {
        id: 'Id',
        teamMember: 'Team Member',
        projectManager: 'Project Manager',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Project Team',
      },
      view: {
        title: 'View Project Team',
      },
      importer: {
        title: 'Import Project Teams',
        fileName: 'projectTeam_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    projectManager: {
      name: 'projectManager',
      label: 'Project Managers',
      menu: 'Project Managers',
      exporterFileName: 'projectManager_export',
      list: {
        menu: 'Project Managers',
        title: 'Project Managers',
      },
      create: {
        success: 'Project Manager saved successfully',
      },
      update: {
        success: 'Project Manager updated successfully',
      },
      destroy: {
        success: 'Project Manager deleted successfully',
      },
      destroyAll: {
        success: 'Project Manager(s) deleted successfully',
      },
      edit: {
        title: 'Edit Project Manager',
      },
      fields: {
        id: 'Id',
        user: 'User',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Project Manager',
      },
      view: {
        title: 'View Project Manager',
      },
      importer: {
        title: 'Import Project Managers',
        fileName: 'projectManager_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    teamContractFile: {
      name: 'teamContractFile',
      label: 'Team Contract Files',
      menu: 'Team Contract Files',
      exporterFileName: 'teamContractFile_export',
      list: {
        menu: 'Team Contract Files',
        title: 'Team Contract Files',
      },
      create: {
        success: 'Team Contract File saved successfully',
      },
      update: {
        success: 'Team Contract File updated successfully',
      },
      destroy: {
        success: 'Team Contract File deleted successfully',
      },
      destroyAll: {
        success: 'Team Contract File(s) deleted successfully',
      },
      edit: {
        title: 'Edit Team Contract File',
      },
      fields: {
        id: 'Id',
        contract: 'Contract',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Team Contract File',
      },
      view: {
        title: 'View Team Contract File',
      },
      importer: {
        title: 'Import Team Contract Files',
        fileName: 'teamContractFile_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    template: {
      name: 'template',
      label: 'Templates',
      menu: 'Templates',
      list: {
        menu: 'Templates',
        title: 'Templates',
      },
      create: {
        success: 'Template saved successfully',
      },
      update: {
        success: 'Template updated successfully',
      },
      destroy: {
        success: 'Template deleted successfully',
      },
      destroyAll: {
        success: 'Template(s) deleted successfully',
      },
      edit: {
        title: 'Edit Template',
      },
      fields: {
        id: 'Id',
        template: 'Template',
        templateType: 'Template Type',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        templateType: {
          costs: 'Costs',
          gantt: 'Gantt',
          projectReport: 'Project Report',
          ethicsCommitteeGuidelines: 'Ethics Committee Guidelines',
          cooperationAgreement: 'Cooperation Agreement',
          employmentContract: 'Employment Contract',
        },
      },
      new: {
        title: 'New Template',
      },
      view: {
        title: 'View Template',
      },
    },

    ethicsCommitteeDecision: {
      name: 'ethicsCommitteeDecision',
      label: 'Ethics Committee Decisions',
      menu: 'Ethics Committee Decisions',
      exporterFileName: 'ethicsCommitteeDecision_export',
      list: {
        menu: 'Ethics Committee Decisions',
        title: 'Ethics Committee Decisions',
      },
      create: {
        success: 'Ethics Committee Decision saved successfully',
      },
      update: {
        success: 'Ethics Committee Decision updated successfully',
      },
      destroy: {
        success: 'Ethics Committee Decision deleted successfully',
      },
      destroyAll: {
        success: 'Ethics Committee Decision(s) deleted successfully',
      },
      edit: {
        title: 'Edit Ethics Committee Decision',
      },
      fields: {
        id: 'Id',
        decision: 'Decision',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Ethics Committee Decision',
      },
      view: {
        title: 'View Ethics Committee Decision',
      },
      importer: {
        title: 'Import Ethics Committee Decisions',
        fileName: 'ethicsCommitteeDecision_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    cooperationPartnerContract: {
      name: 'cooperationPartnerContract',
      label: 'Cooperation Partner Contracts',
      menu: 'Cooperation Partner Contracts',
      exporterFileName: 'cooperationPartnerContract_export',
      list: {
        menu: 'Cooperation Partner Contracts',
        title: 'Cooperation Partner Contracts',
      },
      create: {
        success: 'Cooperation Partner Contract saved successfully',
      },
      update: {
        success: 'Cooperation Partner Contract updated successfully',
      },
      updateStatus: {
        success:
          'Cooperation Partner Contract status updated successfully',
      },
      releaseRequest: {
        success:
          'Release of Cooperation Partner Contracts successfully requested',
      },
      destroy: {
        success: 'Cooperation Partner Contract deleted successfully',
      },
      destroyAll: {
        success:
          'Cooperation Partner Contracts(s) deleted successfully',
      },
      edit: {
        title: 'Edit Cooperation Partner Contract',
      },
      fields: {
        id: 'Id',
        cooperationPartner: 'Cooperation Partner',
        contract: 'Contract',
        status: 'Status',
        dateRange: 'Date',
        date: 'Date',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        status: {
          released: 'Released',
          revise: 'Revise',
          releaseRequested: 'Release Requested',
        },
      },
      new: {
        title: 'New Cooperation Partner Contracts',
      },
      view: {
        title: 'View Cooperation Partner Contracts',
      },
      importer: {
        title: 'Import Cooperation Partner Contracts',
        fileName: 'cooperationPartnerContract_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    employmentContract: {
      name: 'employmentContract',
      label: 'Employment Contracts',
      menu: 'Employment Contracts',
      exporterFileName: 'employmentContract_export',
      list: {
        menu: 'Employment Contracts',
        title: 'Employment Contracts',
      },
      create: {
        success: 'Employment Contract saved successfully',
      },
      update: {
        success: 'Employment Contract updated successfully',
      },
      destroy: {
        success: 'Employment Contract deleted successfully',
      },
      destroyAll: {
        success: 'Employment Contract(s) deleted successfully',
      },
      updateStatus: {
        success: 'Employment Contract status updated successfully',
      },
      releaseRequest: {
        success:
          'Release of Employment Contracts successfully requested',
      },
      edit: {
        title: 'Edit Employment Contract',
      },
      fields: {
        id: 'Id',
        teamMember: 'Team Member',
        contract: 'Contract',
        status: 'Status',
        dateRange: 'Date',
        date: 'Date',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        status: {
          released: 'Released',
          revise: 'Revise',
          releaseRequested: 'Release Requested',
        },
      },
      new: {
        title: 'New Employment Contract',
      },
      view: {
        title: 'View Employment Contract',
      },
      importer: {
        title: 'Import Employment Contracts',
        fileName: 'employmentContract_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    projectGantt: {
      name: 'projectGantt',
      label: 'Gantt Charts',
      menu: 'Gantt Charts',
      exporterFileName: 'gantt_export',
      list: {
        menu: 'Gantt Charts',
        title: 'Gantt Charts',
      },
      create: {
        success: 'Gantt Chart saved successfully',
      },
      update: {
        success: 'Gantt Chart updated successfully',
      },
      destroy: {
        success: 'Gantt Chart deleted successfully',
      },
      destroyAll: {
        success: 'Gantt Chart(s) deleted successfully',
      },
      edit: {
        title: 'Edit Gantt Chart',
      },
      fields: {
        id: 'Id',
        workPackage: 'WorkPackage',
        ganntChart: 'Gannt Chart',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Gantt Chart',
      },
      view: {
        title: 'View Gantt Chart',
      },
      importer: {
        title: 'Import Gantt Charts',
        fileName: 'gantt_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    workPackage: {
      name: 'workPackage',
      label: 'WorkPackages',
      menu: 'WorkPackages',
      exporterFileName: 'workPackage_export',
      list: {
        menu: 'WorkPackages',
        title: 'WorkPackages',
      },
      create: {
        success: 'WorkPackage saved successfully',
      },
      update: {
        success: 'WorkPackage updated successfully',
      },
      destroy: {
        success: 'WorkPackage deleted successfully',
      },
      destroyAll: {
        success: 'WorkPackage(s) deleted successfully',
      },
      edit: {
        title: 'Edit WorkPackage',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        milestoneRange: 'Milestone',
        milestone: 'Milestone',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New WorkPackage',
      },
      view: {
        title: 'View WorkPackage',
      },
      importer: {
        title: 'Import WorkPackages',
        fileName: 'workPackage_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    finalProjectReport: {
      name: 'finalProjectReport',
      label: 'Final Project Reports',
      menu: 'Final Project Reports',
      exporterFileName: 'finalProjectReport_export',
      list: {
        menu: 'Final Project Reports',
        title: 'Final Project Reports',
      },
      create: {
        success: 'Final Project Report saved successfully',
      },
      update: {
        success: 'Final Project Report updated successfully',
      },
      destroy: {
        success: 'Final Project Report deleted successfully',
      },
      destroyAll: {
        success: 'Final Project Report(s) deleted successfully',
      },
      edit: {
        title: 'Edit Final Project Report',
      },
      fields: {
        id: 'Id',
        finalReport: 'Final Report',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Final Project Report',
      },
      view: {
        title: 'View Final Project Report',
      },
      importer: {
        title: 'Import Final Project Reports',
        fileName: 'finalProjectReport_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    projectCompletion: {
      name: 'projectCompletion',
      label: 'Project Completions',
      menu: 'Project Completions',
      exporterFileName: 'projectCompletion_export',
      list: {
        menu: 'Project Completions',
        title: 'Project Completions',
      },
      create: {
        success: 'Project Completion saved successfully',
      },
      update: {
        success: 'Project Completion updated successfully',
      },
      destroy: {
        success: 'Project Completion deleted successfully',
      },
      destroyAll: {
        success: 'Project Completion(s) deleted successfully',
      },
      edit: {
        title: 'Edit Project Completion',
      },
      fields: {
        id: 'Id',
        finalReport: 'Final Report',
        finalSettlement: 'Final Settlement',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Project Completion',
      },
      view: {
        title: 'View Project Completion',
      },
      importer: {
        title: 'Import Project Completions',
        fileName: 'projectCompletion_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    finalSettlement: {
      name: 'finalSettlement',
      label: 'Final Settlements',
      menu: 'Final Settlements',
      exporterFileName: 'finalSettlement_export',
      list: {
        menu: 'Final Settlements',
        title: 'Final Settlements',
      },
      create: {
        success: 'Final Settlement saved successfully',
      },
      update: {
        success: 'Final Settlement updated successfully',
      },
      destroy: {
        success: 'Final Settlement deleted successfully',
      },
      destroyAll: {
        success: 'Final Settlement(s) deleted successfully',
      },
      edit: {
        title: 'Edit Final Settlement',
      },
      fields: {
        id: 'Id',
        settlement: 'Settlement',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Final Settlement',
      },
      view: {
        title: 'View Final Settlement',
      },
      importer: {
        title: 'Import Final Settlements',
        fileName: 'finalSettlement_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },
  },

  auth: {
    profile: {
      title: 'Edit Profile',
      success: 'Profile updated successfully',
    },
    createAnAccount: 'Create an account',
    rememberMe: 'Remember me',
    forgotPassword: 'Forgot password',
    signin: 'Sign in',
    signup: 'Sign up',
    requestRegistration: 'Request registration',
    signout: 'Sign out',
    alreadyHaveAnAccount: 'Already have an account? Sign in.',
    signinWithAnotherAccount: 'Sign in with another account',
    emailUnverified: {
      message: `Please confirm your email at <strong>{0}</strong> to continue.`,
      submit: `Resend email verification`,
    },
    emptyPermissions: {
      message: `You have no permissions yet. Wait for the admin to grant you privileges.`,
    },
    passwordResetRequest: {
      message: 'Request password reset',
      error: `Email not recognized`,
    },
    passwordReset: {
      message: 'Reset password',
    },
    emailAddressVerificationEmail: {
      error: `Email not recognized`,
    },
    verificationEmailSuccess: `Verification email sent successfully`,
    passwordResetEmailSuccess: `Password reset email sent successfully`,
    passwordResetSuccess: `Password changed successfully`,
    verifyEmail: {
      success: 'Email successfully verified',
      message: 'Just a moment, your email is being verified...',
    },
  },

  passwordChange: {
    title: 'Change password',
    currentPassword: 'Current password',
    newPassword: 'New password',
    confirmNewPassword: 'Confirm new password',
    containsEightCharacters: '8 Characters',
    containsNumber: 'Contains Number',
    containsUppercase: 'Contains Uppercase',
    containsSpecialCharacter: 'Contains Special Character',
    passwordUpdateSuccess: 'Password successfully updated',
    passwordUpdateFailed: 'Password update failed',
  },

  roles: {
    //TODO
    //Define description for the user roles
    itAdmin: {
      label: 'IT Administrator',
      description: 'Full access to all resources',
    },
    vrAdmin: {
      label: 'VR Administrator',
      description: 'Full access to all resources',
    },
    projectManager: {
      label: 'Project Manager',
      //description: 'Full access to Project resources',
    },
    projectTeam: {
      label: 'Project Team',
      //description: 'Edit access to Project resources',
    },
    deanship: {
      label: 'Deanship',
      //description: 'Deans office',
    },
    chancellor: {
      label: 'Chancellor',
      //description: 'Access to view audit logs',
    },
    legalDepartment: {
      label: 'Legal Department',
      //description: `Full access to manage users roles`,
    },
    hrDepartment: {
      label: 'HR Department',
      //description: 'Edit access to all entities',
    },
  },

  iam: {
    title: 'Identity and Access Management',
    menu: 'IAM',
    disable: 'Disable',
    disabled: 'Disabled',
    enabled: 'Enabled',
    enable: 'Enable',
    removeRoles: 'Remove roles',
    doEnableSuccess: 'User enabled successfully',
    doDisableSuccess: 'User disabled successfully',
    doDisableAllSuccess: 'User(s) disabled successfully',
    doEnableAllSuccess: 'User(s) enabled successfully',
    doAddSuccess: 'User(s) saved successfully',
    doUpdateSuccess: 'User saved successfully',
    viewBy: 'View By',
    users: {
      name: 'users',
      label: 'Users',
      exporterFileName: 'users_export',
      doRemoveAllSelectedSuccess: 'Permissions removed successfully',
    },
    roles: {
      label: 'Roles',
      doRemoveAllSelectedSuccess: 'Permissions removed successfully',
    },
    edit: {
      title: 'Edit User',
    },
    new: {
      title: 'New User(s)',
      titleModal: 'New User',
      emailsHint:
        'Separate multiple email addresses using the comma character.',
    },
    view: {
      title: 'View User',
      activity: 'Activity',
    },
    importer: {
      title: 'Import Users',
      fileName: 'users_import_template',
      hint:
        'Files/Images columns must be the URLs of the files separated by space. Relationships must be the ID of the referenced records separated by space. Roles must be the role ids separated by space.',
    },
    errors: {
      userAlreadyExists: 'User with this email already exists',
      userNotFound: 'User not found',
      disablingHimself: `You can't disable yourself`,
      revokingOwnPermission: `You can't revoke your own owner permission`,
    },
  },

  user: {
    fields: {
      id: 'Id',
      authenticationUid: 'Authentication Uid',
      avatars: 'Avatar',
      email: 'Email',
      emails: 'Email(s)',
      fullName: 'Name',
      firstName: 'First Name',
      lastName: 'Last Name',
      status: 'Status',
      disabled: 'Disabled',
      phoneNumber: 'Phone Number',
      faculty: 'Faculty',
      location: 'Location',
      centerInstitution: 'Center/Institution',
      outpatientClinic: 'Outpatient Clinic',
      academicTitle: 'Academic Title',
      role: 'Role',
      createdAt: 'Created at',
      updatedAt: 'Updated at',
      roleUser: 'Role/User',
      roles: 'Roles',
      createdAtRange: 'Created at',
      password: 'Kenwort',
      rememberMe: 'Remember me',
    },
    enabled: 'Enabled',
    disabled: 'Disabled',
    validations: {
      // eslint-disable-next-line
      email: 'Email ${value} is invalid',
    },
  },

  auditLog: {
    menu: 'Audit Logs',
    title: 'Audit Logs',
    exporterFileName: 'audit_log_export',
    entityNamesHint:
      'Separate multiple entities using the comma character.',
    fields: {
      id: 'Id',
      timestampRange: 'Period',
      entityName: 'Entity',
      entityNames: 'Entities',
      entityId: 'Entity ID',
      action: 'Action',
      values: 'Values',
      timestamp: 'Date',
      createdByEmail: 'User Email',
    },
  },
  settings: {
    title: 'Settings',
    menu: 'Settings',
    save: {
      success:
        'Settings saved successfully. The page will reload in {0} seconds for changes to take effect.',
    },
    fields: {
      theme: 'Theme',
    },
    colors: {
      default: 'Default',
      cyan: 'Cyan',
      'geek-blue': 'Geek Blue',
      gold: 'Gold',
      lime: 'Lime',
      magenta: 'Magenta',
      orange: 'Orange',
      'polar-green': 'Polar Green',
      purple: 'Purple',
      red: 'Red',
      volcano: 'Volcano',
      yellow: 'Yellow',
    },
  },
  home: {
    menu: 'Home',
    message: `This page uses fake data for demonstration purposes only.`,
    charts: {
      day: 'Day',
      red: 'Red',
      green: 'Green',
      yellow: 'Yellow',
      grey: 'Grey',
      blue: 'Blue',
      orange: 'Orange',
      months: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
      },
      eating: 'Eating',
      drinking: 'Drinking',
      sleeping: 'Sleeping',
      designing: 'Designing',
      coding: 'Coding',
      cycling: 'Cycling',
      running: 'Running',
      customer: 'Customer',
    },
  },
  errors: {
    backToHome: 'Back to home',
    403: `Sorry, you don't have access to this page`,
    404: 'Sorry, the page you visited does not exist',
    500: 'Sorry, the server is reporting an error',
    429: 'Too many requests. Please try again later.',
    forbidden: {
      message: 'Forbidden',
    },
    validation: {
      message: 'An error occurred',
    },
    defaultErrorMessage: 'Ops, an error occurred',
  },
  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: '${path} is invalid',
      required: '${path} is required',
      oneOf: '${path} must be one of the following values: ${values}',
      notOneOf:
        '${path} must not be one of the following values: ${values}',
      notType: ({ path, type, value, originalValue }) => {
        return `${path} must be a ${type}`;
      },
    },
    string: {
      length: '${path} must be exactly ${length} characters',
      min: '${path} must be at least ${min} characters',
      max: '${path} must be at most ${max} characters',
      matches: '${path} must match the following: "${regex}"',
      email: '${path} must be a valid email',
      url: '${path} must be a valid URL',
      trim: '${path} must be a trimmed string',
      lowercase: '${path} must be a lowercase string',
      uppercase: '${path} must be a upper case string',
      selected: '${path} must be selected',
    },
    number: {
      min: '${path} must be greater than or equal to ${min}',
      max: '${path} must be less than or equal to ${max}',
      lessThan: '${path} must be less than ${less}',
      moreThan: '${path} must be greater than ${more}',
      notEqual: '${path} must be not equal to ${notEqual}',
      positive: '${path} must be a positive number',
      negative: '${path} must be a negative number',
      integer: '${path} must be an integer',
      invalid: '${path} must be a number',
    },
    date: {
      min: '${path} field must be later than ${min}',
      max: '${path} field must be at earlier than ${max}',
    },
    boolean: {},
    object: {
      noUnknown:
        '${path} field cannot have keys not specified in the object shape',
    },
    array: {
      min: '${path} field must have at least ${min} items',
      max:
        '${path} field must have less than or equal to ${max} items',
    },
  },
  /* eslint-disable */
  fileUploader: {
    upload: 'Upload',
    image: 'You must upload an image',
    size: 'File is too big. Max allowed size is {0}',
    formats: `Invalid format. Must be '{0}'.`,
  },
  importer: {
    line: 'Line',
    status: 'Status',
    pending: 'Pending',
    imported: 'Imported',
    error: 'Error',
    total: `{0} imported, {1} pending and {2} with error`,
    importedMessage: `Processed {0} of {1}.`,
    noNavigateAwayMessage:
      'Do not navigate away from this page or import will be stopped.',
    completed: {
      success:
        'Import completed. All rows were successfully imported.',
      someErrors:
        'Processing completed, but some rows were unable to be imported.',
      allErrors: 'Import failed. There are no valid rows.',
    },
    form: {
      downloadTemplate: 'Download the template',
      hint: 'Click or drag the file to this area to continue',
    },
    list: {
      discardConfirm: 'Are you sure? Non-imported data will be lost.',
    },
    errors: {
      invalidFileEmpty: 'The file is empty',
      invalidFileExcel: 'Only excel (.xlsx) files are allowed',
      invalidFileUpload:
        'Invalid file. Make sure you are using the last version of the template.',
      importHashRequired: 'Import hash is required',
      importHashExistent: 'Data has already been imported',
    },
  },

  autocomplete: {
    loading: 'Loading...',
  },

  imagesViewer: {
    noImage: 'No image',
  },
};

export default de;
